<template>
  <div :class="{'example-page': true, 'scrolledTop': scrollTop > 68}">
    <top-header class="doc-t-b" :navs="navs">
    </top-header>
    <!--<div class="model-wrap">-->
      <!--<div-->
              <!--v-for="(item, i) in models"-->
              <!--:key="i"-->
              <!--:class="{'active': item.model_id == curr_model}"-->
              <!--@click="changeModel(item.model_id)"-->
              <!--class="md-btn">{{item.model_name}}</div>-->
    <!--</div>-->
    <!-- mobile model & menus end -->
    <div :class="{'doc-body': true, 'is-m-unfold': m_unfold}">
      <div class="doc-menus" :style="`top: ${scrollTop > 68 ? -70 : (0 - scrollTop)}px;padding-top: 100px;`">
        <div class="menu-wrap">
          <tree-menu
                  :currModel='currModel'
                  v-if="menu_loaded"
                  :active_hash_key="curr_hash_key"
                  ref="tree_menu_root"
                  title=""
                  :children="menus"
                  :layer="0"
                  :clickMenu="menuChange">
          </tree-menu>
        </div>
      </div>
      <div
              :class="['anchor-container', hasH2Anchor ? 'double' : '']"
              :style="`top: ${scrollTop > 68 ? 92 : (160 - scrollTop)}px;`"
              ref="anchor_container">
        <a
                v-for="(item, i) in anchors"
                :key="i"
                :class="['h-' + item.h, item.id == curr_anchor ? 'active' : '']"
                :href="'#' + item.id"
                :title="item.name"
        >{{item.name}}</a>
      </div>
      <div class="doc-container">
        <template v-if="curr_doc.title">
          <div class="doc-title">
            <h1 :title="curr_doc.title">{{curr_doc.title}}</h1>
            <div class="sub-word">更新时间：{{curr_doc.updated_at.substr(0, 10)}}</div>
          </div>
          <div ref="doc_content" id="content" class="vditor-reset" v-html="curr_doc.content"></div>
        </template>
        <div v-if="loaded && !curr_doc.title" class="no-data">暂无内容</div>
      </div>
    </div>
    <div class="example-page-foot">
      <div class="foot-navs">
        <a href="/doc" @click.prevent="toDoc">文档中心</a>
        <a href="/doc" @click.prevent="toDoc">关于我们</a>
        <a href="mailto:OrionBase@ainirobot.com"
           @click="$utils.GATracking('点击意见反馈','点击','首页')">意见反馈</a>
        <a href="/doc" @click.prevent="toDoc">用户协议</a>
        <a href="/doc" @click.prevent="toDoc">隐私政策</a>
      </div>
      <div class="cn-bei">
        <a
                target='_blank'
                href='http://www.beian.miit.gov.cn'
                @click="$utils.GATracking('备案号','点击','首页底导-ICP备案号')"
        >猎户星空©2019  • 京公网安备 11010502039185号，京ICP备17016764号-2</a>
      </div>
    </div>
  </div>
</template>

<script>
    import topHeader from '@/components/common/top-header'
    import treeMenu from '@/components/doc/tree-menu.vue'
    import marked from 'marked'
    import urls from '@/container/urls'
    const NAVS = [
        {
            title: '首页',
            route_name: 'home',
            path: '/'
        },
        {
            title: '文档中心',
            route_name: 'doc',
            path: '/doc'
        }
    ]
    export default {
        components: {
            topHeader,
            treeMenu
        },
        data() {
            return {
                lang:1,
                scrollTop: 0,
                navs: NAVS,
                models: [],
                all_menus: [],
                menus: [],
                mobile_menus: [],
                m_unfold: false,
                menu_loaded: false,
                curr_model: '',
                currModel:{},
                curr_hash_key: '',
                curr_doc: {
                    title: '',
                    updated_at: '',
                    content: ''
                },
                curr_anchor: '',
                anchors: [],
                hnode_arr: [],
                task_target_y: 0,
                hasH2Anchor: false,
                loading: true,
                loaded: false
            }
        },
        props: ['m'],
        inject:['reload','switch'],
        computed: {
            curr_model_name() {
                let mod = this.curr_model
                let obj = this.models.find(item => item.model_id == mod)
                return obj ? obj.model_name : ''
            }
        },
        watch: {
            $route (path) {
                if(this.$route.query.lang){
                    this.lang = this.$route.query.lang=='en'?2:1
                }
                if(!path.hash){
                    if (window.__IS_PRERENDER) return
                    let { m, h } = this.$route.query
                    let hash = this.$route.hash || ''
                    let anchor = decodeURIComponent(hash)
                    this.curr_model = m || ''
                    this.curr_hash_key = h || ''
                    this.checkNetType(this.curr_model,this.curr_hash_key)
                    this.curr_anchor = anchor ? anchor.substr(1) : ''
                    this.getModels()
                }
                // window.addEventListener('scroll', this.handleScroll)
                // this.handleScroll()
            },
        },
        mounted() {
            if(this.$route.query.lang){
                this.lang = this.$route.query.lang=='en'?2:1
            }
            if (window.__IS_PRERENDER) return
            let { m = this.m, h } = this.$route.query
            let hash = this.$route.hash || ''
            let anchor = decodeURIComponent(hash)
            this.curr_model = m || ''
            this.curr_hash_key = h || ''
            this.checkNetType(this.curr_model,this.curr_hash_key)
            this.curr_anchor = anchor ? anchor.substr(1) : ''
            this.getModels()
            window.addEventListener('scroll', this.$utils.throttle(this.handleScroll,16))
            this.handleScroll()
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.$utils.throttle(this.handleScroll,16))
        },
        methods: {
            async checkNetType(m,n){
                if(m&&n){
                    let { header, data } = await this.$axios.get(urls.check_net_type,{model:m,docs:n})
                    if(header.code==0){
                    }else{
                        this.$swal({
                            toast: true,
                            position: 'top',
                            showConfirmButton: false,
                            text: header.msg,
                            timer: 5000
                        })
                    }
                }
            },
            setLau(num){
                this.lang = num;
                let lang = num==2?"en":"cn"
                this.$router.push({path:this.$route.path,query:{...this.$route.query,lang},replace:false})
                // this.getModels('change')
                this.reload(true)
            },
            handleScroll(e) {
                let scrollTop =
                    window.pageYOffset ||
                    document.documentElement.scrollTop ||
                    document.body.scrollTop;
                this.scrollTop = scrollTop;
                if (window.innerWidth <= 1080) return
                if (!this.anchors.length) return
                let hnode_arr = this.hnode_arr
                if (this.task_target_y && this.task_target_y >= scrollTop) {
                    this.task_target_y = ''
                    return
                }
                for (let i = hnode_arr.length - 1; i >= 0; i--) {
                    let el = hnode_arr[i]
                    let offsetTop = el.offsetTop
                    let targetY = offsetTop - 103
                    if (scrollTop >= targetY) {
                        this.curr_anchor = this.anchors[i].id
                        let anchor_el = this.$refs.anchor_container && this.$refs.anchor_container.children[i] || null
                        anchor_el && anchor_el.scrollIntoView({
                            block: 'nearest',
                            // inline: '',
                        })
                        break
                    }
                }
            },
            async getModels(change) {
                let { header, data } = await this.$axios.get(urls.get_models)
                if (header.code == 0 && Array.isArray(data)&&data.length>0) {
                    this.models = data
                    let flag = this.models.find(i=>i.model_id==this.curr_model)
                    this.currModel = flag
                    if (this.switch.flag||!flag) {
                        this.curr_model = data[0].model_id
                    }
                }else{
                    this.curr_model=''
                    this.models = []
                    this.all_menus = [];
                    //  this.menu_loaded = false
                }
                this.getMenu(change)
            },
            async getMenu(change) {
                let mods = this.models.map(item => item.model_id)
                let params = { model_id: mods.join(',') }
                if(!params.model_id){
                    this.curr_hash_key=''
                    this.updateDoc()
                    this.resolveMobileMenu()
                    this.updateShowMenu()
                    return
                }
                const { header, data } = await this.$axios.get(urls.get_menus, params)
                if (header.code == 0 && Array.isArray(data)) {
                    this.all_menus = [...data]
                    this.resolveMobileMenu()
                    this.updateShowMenu()
                    let hashAry = []
                    let checkHash =function (menu){
                        menu.forEach(i=>{
                            if(i.hash_key){
                                hashAry.push(i.hash_key)
                            }
                            if(Array.isArray(i.children)&&i.children.length>0){
                                checkHash(i.children)
                            }
                        })
                    };
                    checkHash(this.all_menus)
                    let flag = hashAry.includes(this.curr_hash_key)
                    if (!flag||change||!this.curr_hash_key) {
                        this.curr_hash_key = this.menus.length > 0 ? this.menus[0].hash_key : ''
                    }
                    this.updateDoc(change)
                } else {
                    this.all_menus = []
                }
                this.menu_loaded = true
            },
            updateShowMenu() {
                let mod = this.curr_model
                this.menus = this.all_menus.filter(item => item.model_id == mod)
                let k = 0;
                let fn = (data)=>{
                    return data.map(i=>{
                        if(i.children&&i.children.length>0){
                            i.unfold = k == 0 ? true : false
                            i.children = fn(i.children)
                            k = 1
                        }
                        return i
                    })
                }
                this.menus = fn(this.$utils.deepCopy(this.menus))
            },
            async updateDoc(change) {
                let hash_key = this.curr_hash_key
                if (!hash_key) {
                    this.curr_doc = {
                        title: '',
                        updated_at: '',
                        content: ''
                    }
                    this.hnode_arr = []
                    this.anchors = []
                    this.curr_anchor = ''
                    this.loaded = true
                    if(this.curr_model){
                        let path = `/example?m=${this.curr_model}&lang=${this.lang==2?'en':'cn'}`
                        this.$router.push({path,replace:false})
                    }
                    return
                }
                let path = `/example?m=${this.curr_model}&h=${hash_key}&lang=${this.lang==2?'en':'cn'}`
                if (change!='change'&&this.curr_anchor) {
                    path += ('#' + this.curr_anchor)
                }
                this.$router.push(path) // 更改当前url展示的路径
                this.loading = true
                this.loaded = false
                let params = { hash_key }

                const { header, data } = await this.$axios.get(urls.get_docs, params)
                if (header.code == 0 && data) {
                    let { title, updated_at, content} = data
                    let md = unescape(content)
                    let html = marked(md)
                    html = html.replace(/<img src="(http:?)\/\/orion-base-test/g,'<img src="https://orion-base-test')
                    this.curr_doc = {
                        title,
                        updated_at,
                        content: html
                    }
                    this.$nextTick(() => {
                        this.genAnchors(change)

                        this.$utils.addClass('pre')
                        this.$utils.addClass('pre code')
                        try{
                            Prism.highlightAll()
                        }catch (e) {
                            console.log(e);
                        }

                    })
                } else {
                    this.$swal({
                        toast: true,
                        position: 'top',
                        showConfirmButton: false,
                        text: header.msg,
                        timer: 5000
                    })
                    this.curr_doc = {
                        title: '',
                        updated_at: '',
                        content: ''
                    }
                }
                this.loading = false
                this.loaded = true
            },
            resolveMobileMenu() {
                let arr = []
                let obj = {}
                let all_menus = this.all_menus.sort((a, b) => a.model_id - b.model_id)
                let models = this.models
                all_menus.forEach(me => {
                    let mod = me.model_id
                    if (obj.hasOwnProperty(mod)) {
                        obj[mod].children.push(me)
                    } else {
                        obj[mod] = {
                            children: [me],
                            model_id: mod
                        }
                    }
                })
                this.models.forEach(item => {
                    let mod = item.model_id
                    let o = {...obj[mod]}
                    if (!o.model_id) {
                        o.model_id = mod
                    }
                    if (!o.children) {
                        o.children = []
                    }
                    o.title = item.model_name
                    o.is_root = true
                    arr.push(o)
                })
                this.mobile_menus = arr
            },
            toggleMobileMenus() {
                this.m_unfold = !this.m_unfold
            },
            genAnchors(change) {
                let nodes = this.$refs.doc_content.children || []
                let nodesArr = Array.prototype.slice.call(nodes)
                let arr = []
                let hnode_arr = []
                this.hasH2Anchor = false
                nodesArr.forEach(node => {
                    let nodeName = node.nodeName.toLowerCase()
                    if (nodeName == 'h2' || nodeName == 'h3') {
                        node.setAttribute('id', node.innerText)
                        if (nodeName == 'h2') {
                            this.hasH2Anchor = true
                        }
                        arr.push({
                            id: node.innerText,
                            name: node.innerText,
                            h: nodeName.substr(1)
                        })
                        hnode_arr.push(node)
                    }
                })
                this.hnode_arr = hnode_arr
                this.anchors = arr
                if (!this.curr_anchor) {
                    this.curr_anchor = arr.length > 0 ? arr[0].id : ''
                } else {
                    if(!change){
                        this.toAnchorView()
                    }else{
                        this.curr_anchor=''
                    }
                }
            },
            toAnchorView() {
                let anchor = this.curr_anchor
                let el = document.getElementById(anchor)
                if (el) {
                    let x =
                        window.pageXOffset ||
                        document.documentElement.scrollLeft ||
                        document.body.scrollLeft || 0
                    let offsetTop = el.offsetTop
                    let targetY = offsetTop - 103
                    this.task_target_y = targetY
                    window.scrollTo(x, targetY)
                }
            },
            clickAnchorMap(id) {
                let path = `/example?m=${this.curr_model}&h=${this.curr_hash_key}#${id}`
                this.curr_anchor = id
                this.$router.push(path)
                this.toAnchorView()
            },
            changeModel(m) {
                if (m == this.curr_model) return
                this.curr_model = m
                this.updateShowMenu()
                this.curr_hash_key = this.menus&&this.menus.length==0?'':this.menus[0].hash_key
                this.curr_anchor = ''
                this.updateDoc()
            },
            menuChange(menu) {
                let model_id = menu.model_id
                let hash_key = menu.hash_key
                if (hash_key == this.curr_hash_key) return;
                this.curr_hash_key = menu.hash_key
                this.curr_model = model_id
                this.curr_anchor = ''
                this.updateDoc()
                if (this.m_unfold && menu.children.length < 1) {
                    this.m_unfold = false
                }
            }
        }
    }
</script>

<style lang="less">
  @import url("../../assets/style/custom-editor.less");
  @bgGray: rgba(244, 245, 246, 1);
  @borderGray: rgba(234, 234, 234, 1);
  @blue: #6976FF;
  i{
    font-style:normal;
  }
  .example-page {
    position: relative;
    .el-dropdown-link {
      background: #f4f5f6!important;
    }
    &.scrolledTop {
      .model-wrap {
        position: fixed;
        top: 0;
      }
    }
    .doc-t-b {
      height: 68px;
      padding: 0 60px;
      background: @bgGray;
      position: relative;
      .select-language-mobile{
        height: 56px;
        line-height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 64px;
        i{
          height: 10px;
          margin: 0 6px;
          width: 1px;
          background: #666;
        }
        .cn{
          font-size: 14px;
        }
      }
    }
    .model-wrap {
      position: absolute;
      top: 68px;
      width: 100%;
      height: 78px;
      z-index: 100;
      line-height: 78px;
      text-align: center;
      background: @bgGray;
      border-top: 1px solid @borderGray;
      .md-btn {
        display: inline-block;
        margin: 0 5px;
        min-width: 78px;
        // width: 102px;
        padding: 0 24px;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        // padding: 0 16px;
        user-select: none;
        cursor: pointer;
        color: #2E2E2E;
        background: rgba(255, 255, 255, 1);
        text-align: center;
        border-radius: 4px;
        transition: all .2s;
        &.active {
          color: #fff;
          background: @blue;
        }
        &:not(.active):hover {
          background: rgba(255, 255, 255, .4);
        }
      }
    }
    .select-language{
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 70px;
      top: 19px;
      height: 38px;
      line-height: 38px;
      cursor: pointer;
      transition: opacity .3s;
      span{
        width: 44px;
        margin: 0 2px;
        display: inline-block;
      }
      span:hover{
        width: 44px;
        height: 30px;
        background: #DFDFDF;
        border-radius: 16px;
        line-height: 30px;
      }
      i{
        font-style:normal;
        color: #dedede;
      }
      .cn{
        font-size: 14px;
      }
      .en{
        font-size: 15px;
      }
    }
    .doc-body {
      margin-top: 0px;
      min-height: calc(100vh - 147px);
    }
    .doc-menus, .anchor-container {
      position: fixed;
      top: 147px;
    }
    .doc-menus {
      bottom: 0;
      left: 0;
      width: 240px;
      box-sizing: border-box;
      // padding-top: 50px;
      // padding-left: 30px;
        background: #E5EAF3;
      overflow: auto;
    }
    .doc-container {
      background: #fff;
      margin-left: 260px;
      margin-right: 279px;
      padding: 30px 0 130px 0;
      box-sizing: border-box;
      .doc-title {
        margin-bottom: 30px;
        padding-bottom: 26px;
        border-bottom: 1px solid @borderGray;
        h1 {
          font-size: 32px;
          line-height: 1.4;
          margin-bottom: 10px;
          color: #222222;
          white-space: normal;
          word-break: break-all;
        }
        .sub-word {
          font-size: 14px;
          line-height: 14px;
          color: #A3A3A3;
        }
      }

    }
    .anchor-container {
      top: 197px;
      right: 60px;
      max-width: 200px;
      max-height: 300px;
      overflow-y: auto;
      background: #fff;
      &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #dfdfdf;
        border-radius: 2px;
        opacity: 0.5;
      }
      a {
        display: block;
        min-width: 70px;
        max-width: 100%;
        height: 36px;
        line-height: 36px;
        padding: 0 16px;
        font-size: 14px;
        border-left: 1px solid #D7DBE9;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-decoration: none;
        color: #464646;
        &:hover {
          color: @blue;
        }
        &.active {
          border-left-color: @blue;
          background: rgba(239, 243, 255, 1);
          color: @blue;
        }
      }
      &.double {
        .h-3 {
          padding-left: 26px;
        }
      }
    }
    .example-page-foot {
      position: absolute;
      bottom: 0;
      left: 300px;
      right: 330px;
      padding: 10px 0 10px 70px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 14px;
      color: rgba(27, 26, 58, 0.6);
      a {
        text-decoration: none;
        font-size: 14px;
        color: rgba(27, 26, 58, 0.6);
        margin-right: 30px;
        &:hover {
          text-decoration: underline;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
      .foot-navs, .cn-bei {
        line-height: 2;
      }
    }
    .m-model-menus {
      display: none;
      .now-selected {
        position: fixed;
        top: 56px;
        left: 0;
        right: 0;
        z-index: 100;
        height: 56px;
        background: #fff;
        box-sizing: border-box;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 1px;
          background: rgba(221, 221, 221, 1);
          transform: scaleY(0.5);
        }
        div {
          padding: 0 25px;
          line-height: 56px;
          font-size: 17px;
          color: #1B1939;
        }
        .arrow-down {
          position: absolute;
          right: 25px;
          top: 20px;
          transition: transform .2s cubic-bezier(.21, .70, .13, .86);
          &.unfold {
            transform: rotateZ(180deg);
            &::before {
              border-top-color: #535353;
            }
          }
        }
      }
      .m-menu-tree {
        overflow: auto;
        position: fixed;
        top: 112px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
        background: #fff;
        .menu-item.single.active::before {
          content: "";
          display: block;
          width: 4px;
          height: 4px;
          margin-right: 8px;
          border-radius: 2px;
          background: @blue;
        }
      }
    }

    .arrow-down {
      display: block;
      width: 16px;
      height: 16px;
      &::before, &::after {
        position: absolute;
        top: 5px;
        left: 2px;
        content: "";
        display: block;
        height: 0;
        font-size: 0;
        border: 6px solid transparent;
        border-top: 6px solid #666;
        transform: scaleX(0.95);
      }
      &::after {
        top: 4px;
        border-top: 6px solid #fff;
      }
    }
  }

  @media screen and (max-width: 1700px) {
    .example-page {
      .example-page-foot {
        display: block;
        .foot-navs, .cn-bei {
          text-align: center;
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .example-page {
      .example-page-foot {
        font-size: 13px;
        a {
          font-size: 13px;
        }
      }
    }
  }

  @media screen and (min-width: 1081px) {
    .example-page {
      .doc-t-b {
        position: relative;
      }
    }
  }

  @media screen and (max-width: 1080px) {
    .example-page {
      .cn-top.doc-t-b {
        position: fixed;
        z-index: 200;
        height: 56px !important;
        padding: 0 !important;
        .top-header {
          padding: 0 24px !important;
          .ob-logo img {
            width: 120px;
          }
          .pc-top-navs {
            display: none;
          }
          .m-top-navs {
            display: block;
          }
        }
      }
      .m-model-menus {
        display: block;
      }
      .model-wrap {
        display: none;
      }
      .doc-body {
        margin-top: 112px;
        min-height: calc(100vh - 112px);
        &.is-m-unfold .doc-container {
          max-height: calc(100vh - 112px);
          overflow: hidden;
        }
      }
      .doc-menus {
        display: none;
      }
      .anchor-container {
        display: none;
      }
      .doc-container {
        margin-left: 0;
        margin-right: 0;
        padding: 40px 24px 140px;
      }
      .example-page-foot {
        left: 0;
        right: 0;
        padding: 10px 25px;
        background: #F7F7F7;
        font-size: 13px;
        text-align: center;
        a {
          font-size: 13px;
          margin: 0;
        }
        .foot-navs {
          line-height: 2.4;
          a {
            margin: 0 3vw;
          }
          a:first-of-type {
            margin-left: 0;
          }
          a:last-of-type {
            margin-right: 0;
          }
        }
        .cn-bei {
          line-height: 2.4;
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    .example-page {
      .cn-top {
        .top-header {
          padding: 0 20px !important;
        }
      }
      .doc-container {
        padding: 40px 20px 140px;
      }
      .example-page-foot {
        padding: 10px 20px;
        .foot-navs a {
          margin: 0 2vw;
        }
      }
    }
  }

  @media screen and (max-width: 374px) {
    .example-page {
      .example-page-foot {
        font-size: 12px;
        a {
          font-size: 12px;
        }
        .foot-navs a {
          margin: 0 1.5vw;
        }
      }
    }
  }
</style>
